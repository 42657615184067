import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';

import { productShape } from 'shapes/product';
import makeComponentForm from 'react/business/components/form/Form';

import { ComponentFormCurrentStepContext } from 'react/business/components/form/Context';
import ComponentFormSteps from 'react/business/components/form/steps/ComponentFormSteps';
import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';
import withFormStepsTouched from 'react/business/components/form/steps/withFormStepsTouched';

import { elementShape } from 'shapes/vendor';

import ComponentFormLayout from '../layout/Layout';
import ComponentFormPreview from '../preview/Preview';
import withComponentFromFormValues from '../withComponentFromFormValues';
import withPreviewOpenState from '../preview/withPreviewOpenState';

import classNames from './component-create-form-page.module.scss';
import StepsRenderer from '../layout/StepsRenderer';

const ConnectedComponentFormPreview = withComponentFromFormValues(ComponentFormPreview);

const DefaultTouchedComponentFormSteps = compose(
  withFormStepsTouched(),
)(ComponentFormSteps);

const enhancer = compose(
  withPropsOnChange(
    () => false,
    () => ({
      ComponentForm: makeComponentForm(),
    }),
  ),

  withPreviewOpenState,
  memo,
);

const ComponentCreateFormPage = ({
  ComponentForm,
  step,
  initialValues,
  onChangeStep,
  onEditComponent,
  onSubmitSuccess,
  onSubmitFail,
  isPreviewOpen,
  onOpenPreview,
  onClosePreview,
}) => (
  <ComponentFormCurrentStepContext.Provider value={step}>
    <ComponentForm
      initialValues={initialValues}
      onEditComponent={onEditComponent}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      className={classNames.form}
    >
      <ComponentFormLayout
        steps={(
          <DefaultTouchedComponentFormSteps
            value={step}
            onChange={onChangeStep}
          />
        )}
      >
        <StepsRenderer
          step={step}
          onChangeStep={onChangeStep}
          onOpenPreview={onOpenPreview}
        />

        <ConnectedComponentFormPreview
          isOpen={isPreviewOpen}
          onRequestClose={onClosePreview}
        />
      </ComponentFormLayout>
    </ComponentForm>
  </ComponentFormCurrentStepContext.Provider>
);

ComponentCreateFormPage.propTypes = {
  ComponentForm: elementShape.isRequired,
  step: PropTypes.string,
  onChangeStep: PropTypes.func,
  onEditComponent: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  /** Form initial values. */
  initialValues: productShape,
  isPreviewOpen: PropTypes.bool.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onClosePreview: PropTypes.func.isRequired,
};

ComponentCreateFormPage.defaultProps = {
  step: COMPONENT_FORM_STEPS.PRESENTATION,
  onChangeStep: noop,
  onEditComponent: noop,
  onSubmitSuccess: noop,
  onSubmitFail: noop,
  initialValues: null,
};

export default enhancer(ComponentCreateFormPage);
