import React, { memo } from 'react';
import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import ROUTES from 'react/routes';
import { getCommunities } from 'redux/communities/actions';

import withRouteQueryString from 'react/hoc/withRouteQueryString';
import withStepInRoute from 'react/generic/steps/containers/withValueInRoute';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import ComponentCreateFormPage from 'react/pages/component/form/create/ComponentCreateFormPage';
import queryString from 'query-string';
import withSubmitHandlers from '../submit-handlers';

import messages from './component-create-form.messages';
import { getParentProduct } from '../../../../../redux/products/actions';

const enhancer = compose(
  memo,
);

const EnhancedComponentCreateFormPage = compose(
  withRouter,

  // Step in URL get/set.
  withStepInRoute(
    // Get step from URL.
    ({ match }) => match.params.step,
    // Set step in URL.
    (step, { history, location }) => {
      const { parent } = queryString.parse(location.search) || {};
      return history.push(
        `${formatRoute(ROUTES.COMPONENT.CREATE_FORM.FORM, { step })}?parent=${parent}`,
      );
    },
    // Map value/onChange to step/onChangeStep.
    {
      propsMapper: {
        value: 'step',
        onChange: 'onChangeStep',
      },
      omitRouterProps: false,
    },
  ),

  withRouteQueryString(
    ({ parent }) => ({ parent, initialValues: {} }
    ),
  ),

  connect(
    null,
    {
      getCommunities,
      getParentProduct,
    },
  ),

  withSubmitHandlers,

  lifecycle({
    componentDidMount() {
      this.props.getCommunities({ populateDomains: true });
      this.props.getParentProduct({ parentDigitalProduct: this.props.parent });
    },
  }),
)(ComponentCreateFormPage);

const ComponentCreateFormView = () => (
  <>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <EnhancedComponentCreateFormPage />
  </>
);

export default enhancer(ComponentCreateFormView);
